// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios'
import IAuthMedico from '@/models/IAuthMedico'
import Cookies from 'js-cookie'

const AuthService = {
  setToken: (token: string) => Cookies.set('medico_token', token),
  getToken: () => Cookies.get('medico_token'),
  removeToken: () => Cookies.remove('medico_token'),
  isAuthenticated: () => !(Cookies.get('medico_token') == null),
  async loginMedico(email: string, senha: string): Promise<IAuthMedico> {
    const response = await axios.post<IAuthMedico>('/medico/login', { email, senha })
    this.setToken(response.data.token)
    return response.data
  },
  async registrar(email: string, senha: string, hash: string, nome: string, crm: string, crp: string, crn: string, tipo_atendimento: string): Promise<IAuthMedico> {
    const response = await axios.post<IAuthMedico>('/medico/registrar', { email, senha, hash, nome, crm, tipo_atendimento, crp, crn, primeiro_acesso: false })
    this.setToken(response.data.token)
    return response.data
  },
  logout() {
    this.removeToken()
  },
}

export default AuthService
