
import Vue from 'vue'
import MedicoHeader from '@/components/Layout/MedicoHeader.vue'
import authService from '@/services/auth'

export default Vue.extend({
  name: 'App',
  data: () => ({
    snackbar: false,
    text: '',
    color: '',
    timeout: 3000,
  }),
  components: {
    MedicoHeader,
  },
  methods: {
    isAuthenticated() {
      return authService.getToken()
    },
  },
  created() {
    this.$root.$on('snackbar', (text: string, color: 'error' | 'success' | 'info') => {
      this.text = text
      this.color = color
      this.timeout = 3000
      this.snackbar = true
    })
  },
})
