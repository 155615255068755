import Vue from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $snackbar(message: string, type: 'error' | 'success' | 'info'): void
  }
}

Vue.prototype.$snackbar = function (message: string, type: string) {
  this.$root.$emit('snackbar', message, type)
}
