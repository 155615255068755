import { AxiosError } from 'axios'
import Vue from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $errorHandler(error: unknown): string
  }
}

Vue.prototype.$errorHandler = function (error: unknown): string {
  if (error instanceof AxiosError) {
    if (error.response) {
      return error.response.data.message
    }
    return `Error: ${error.message}`
  }
  return 'An unexpected error has occurred'
}
