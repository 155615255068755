import { Commit } from 'vuex';
import IMedico from '../../models/IMedico';

export const SET_MEDICO = 'SET_MEDICO'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
 return {
  id: 0,
  atendimento_ambulatorial: null,
  nome: true,
  email: '',
  tipo_atendimento: '',
 }
}
const state = getDefaultState()

const mutations = {
  [RESET_STATE]: (state: IMedico) => Object.assign(state, getDefaultState()),
  [SET_MEDICO]: (state: IMedico, medico: IMedico) => {
    state.id = medico.id
    state.atendimento_ambulatorial = medico.atendimento_ambulatorial
    state.nome = medico.nome
    state.email = medico.email
    state.tipo_atendimento = medico.tipo_atendimento
  },
}

const getters = {
  medico: (state: IMedico) => state,
}
const actions = {
  resetMedico: ({ commit }: { commit: Commit }) => {
    commit(RESET_STATE)
  },
  setMedico({ commit }: { commit: Commit }, medico: IMedico) {
    console.log(medico)
    commit(SET_MEDICO, medico)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
