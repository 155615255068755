// import Vue from 'vue'
// import VueTheMask, { mask } from 'vue-the-mask'
// import '@/core/filters'

// Vue.directive(mask)

// Vue.use(VueTheMask)

/**
 * Converte um valor em bytes para megabytes.
 * @param {number} bytes - O valor em bytes a ser convertido.
 * @returns {number} O valor equivalente em megabytes.
 */

export function bytesToMegabytes(bytes: number): number {
  const megabytes = bytes / (1024 * 1024)
  return parseFloat(megabytes.toFixed(2))
}

export default {}
